import React from 'react'
import {graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import {Box,Flex,Heading,Text} from '@chakra-ui/react'
import MySEO from '../components/seo'

const Material = ({data}) => {

return(


    <div>
      <MySEO
        title="Material"
        description="Die Wahl des Materials ist eine relante Entscheidung"
      />
    <Heading># markantes</Heading>
    <Text variant="bildtext"><b>Ihr Auftritt sollte nicht von Pappe sein. </b> Auch das Material ist eine Botschaft. Wenn Sie qualitativ hochwertig auftreten und sich vom Durchschnitt abheben wollen, dann nehmen wir uns gerne die Zeit, das richtige Material und die richtige Verpackung für Ihren Auftritt auszusuchen  	&mdash; von der kleinen Visitenkarte über Kartonagen, Schuber, CD-Hüllen, Banderolen bis hin zu Roll-Ups, Bannern und Wandsystemen für Messen und Ausstellungen.

    </Text>
    <Flex>

           <Box width={["100%","100%","49%"]}>
                <GatsbyImage alt="" image= {data.bilder.edges[0].node.childImageSharp.gatsbyImageData} />

           </Box>

       <Box width={["100%","100%","49%"]}>
            <GatsbyImage alt="" image= {data.bilder.edges[1].node.childImageSharp.gatsbyImageData} />
       </Box>

     </Flex>
    </div>
)}


export const MaterialQuery = graphql`
 query {
  bilder:allFile(filter: {absolutePath: {regex: "/_papier/"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
 }


`

  export default Material
